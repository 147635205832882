import React from 'react';
import cx from 'classnames';
import {withStyles} from "@material-ui/core/es/styles";
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from "prop-types";

const MENU_ICON = {
  fontSize: 'inherit',
  viewBox: '0 0 24 24'
};

const styles = theme => ({
  default:{
  }
});

const CancelIcon =({className, ...props}) => {
  const { classes } = props;
  
  return (
      <SvgIcon
          className={cx(
              classes.default,
              className
          )}
          viewBox={MENU_ICON.viewBox}
          {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
      </SvgIcon>
  )
};

CancelIcon.porpTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CancelIcon);
